const colorGreen = {
    red: 19, green: 233, blue: 19
};
  const colorRed = {
    red: 255, green: 0, blue: 0
  };
  const colorOrange = {
    red: 255, green: 165, blue: 0
  };

const ColorGradientTime = (fadeFraction) => {
    let color1 = colorGreen;
    let color2 = colorOrange;

    let fade = (fadeFraction <= 5 ? 0 : fadeFraction <= 10 ? 0.20 : fadeFraction <= 15 ? 0.40 : fadeFraction >= 30 ? 1 : 0.6);

    fade = fade * 2;

    if (fade >= 1) {
      fade -= 1;
      color1 = colorOrange;
      color2 = colorRed;
    }

    const diffRed = color2.red - color1.red;
    const diffGreen = color2.green - color1.green;
    const diffBlue = color2.blue - color1.blue;

    const gradient = {
      red: parseInt(Math.floor(color1.red + (diffRed * fade)), 10),
      green: parseInt(Math.floor(color1.green + (diffGreen * fade)), 10),
      blue: parseInt(Math.floor(color1.blue + (diffBlue * fade)), 10),
    };

    return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
}

export default ColorGradientTime;