import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CheckCircle, Cancel } from "@mui/icons-material";
import ColorGradientAmount from "../assets/ColorGradientAmount"
import ColorGradientTime from "../assets/ColorGradientTime"
import { TextGlower } from '../assets/TextGlower';
import "../styles/dj.css"

const Page = () => {
  const [response, setResponse] = useState([]);
  const [total, setTotal] = useState(0);
  const theme = useTheme();
  const [auth, setAuth] = useState(false);

  const handleGet = async () => {
    try {
      const response = await fetch('/api/get-requests');
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = await response.json();
      
      setResponse(JSON.parse(data.requests));
      setTotal(JSON.parse(data.total));
    } catch (error) {
      setResponse(error.message);
    }
  };

  const handlePost = async (value) => {
    try {
      const response = await  fetch('/api/put-request?action=' + value, {
        method: 'POST',
        body: JSON.stringify(
           selectedValue
        ),
      }
      );      
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      await handleGet();
    } catch (error) {
    }
  };


  const timeDiff = (timestamp) => {
    return (new Date().getTime() - new Date(timestamp).getTime())/60/1000
  }

  useEffect(async () => {

    const authStorage = await sessionStorage.getItem("auth");

    if(!authStorage || authStorage != "true"){
      const pw = prompt("Password")
      if(pw == process.env.GATSBY_PW){
        sessionStorage.setItem("auth", true);
        setAuth(true)
      }
      else{
        sessionStorage.setItem("auth", false);
      }
    }
    else{
      setAuth(authStorage)
    }

    handleGet();
   setInterval(() => {
    handleGet();
   },20000);
   
  }, []);

  const close = () =>{
    setOpen(false)
    setSelected(null)
  };
  const [selectedValue, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
 
  const handleClose = () => {
    close();
  };

  const handleListItemClick = async (value) => {
    setResponse(response.filter( row => row.id != selectedValue.id))
    if(value == 'accept')
      setTotal(total + parseInt(selectedValue.amount))
    close();
    await handlePost(value)
  };

 


  return (
    !auth ? 
    <div>
    <center>{TextGlower("Not authorized!",2)}</center>
    </div> 
    :
    <div id="dj">
      

    <div>
      <center>Total accepted: <b> {total} kr</b></center>
    </div>
    <Dialog onClose={handleClose} open={open}>
      
      <DialogTitle>{selectedValue ? selectedValue.song : null}</DialogTitle>
      <List sx={{ pt: 0 }}>
        
      <ListItem disableGutters>
            <ListItemButton onClick={() => handleListItemClick('accept')}>
              <ListItemAvatar>
                  <CheckCircle sx={{color:'green'}} />
              </ListItemAvatar>
              <ListItemText primary={"Accept"} />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton onClick={() => handleListItemClick('decline')}>
              <ListItemAvatar>
                  <Cancel sx={{color:'red'}} />
              </ListItemAvatar>
              <ListItemText primary={"Decline"} />
            </ListItemButton>
          </ListItem>
      </List>
    </Dialog>

    <Box sx={{ height: 750, maxWidth: "100%", margin:0  }}>
      <DataGrid
      disableColumnMenu 
      onRowClick = {(data) => {
        setSelected(data.row)
        setOpen(!open)
      }}
        rows={response ? response : []}
        initialState={{
          sorting: {
            sortModel: [{ field: 'timestamp', sort: 'desc' }],
          },
        }}
        columns={[
          {
            field: "song",
            headerName: "Låt",
            width: 100,
            flex: 3,
            editable: false,
            renderCell: (params) => (
              <Typography>
                <strong>{params.value}</strong>
              </Typography>
            ),
          },
          
          {
            field: "amount",
            headerName: "Pris",
            type: "number",
            flex: 0.7,
            sortingOrder: ['desc', 'asc', null],
            editable: false,
            
            renderCell: (params) => (
              <Typography sx = {{color: ColorGradientAmount(params.value)}}>{params.value} kr</Typography>
            ),
          },
          {
            field: "timestamp",
            headerName: "Tid",
            flex: 0.7,
            sortingOrder: ['desc', 'asc', null],
            editable: false,
            renderCell: (params) => (
              <Typography sx = {{color: ColorGradientTime(params.value ? Math.round(timeDiff(params.value)) : 30)}}>{params.value ? Math.round(timeDiff(params.value)) : null} min</Typography>
            ),
          }
        ]}
        pageSize={100}
        rowsPerPageOptions={[100]}
        density="compact"
        hideFooterSelectedRowCount
        isRowSelectable={() => false}
        sx={{
          border: 0,

          
          [`& .MuiTypography-root `]: {
            fontSize:"0.7rem",
            whiteSpace: 'wrap',
          },
          [`& .MuiDataGrid-cell `]: {
            borderBottom: "none",
            fontSize:"2px",
            "&:focus-within": {
              outline: "none",
            },
          },
          [`& .Mui-selected `]: {
            background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
          },
          [`& .MuiDataGrid-row `]: {
            background: theme.palette.mode === "dark" ? "#333333" : "none",
            my: 0.3,
            borderRadius: 1,
            [`&:hover`]: {
              background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
            },
          },
          [`& .MuiDataGrid-columnSeparator `]: {
            display: "none",
          },
        }}
      />
    </Box>
    </div>
  );
};

export default Page;
